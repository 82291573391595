import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TypingPractice = () => {
    const [text, setText] = useState('');
    const [inputIndex, setInputIndex] = useState(0);
    const [errors, setErrors] = useState(0);
    const [accuracy, setAccuracy] = useState(100);
    const textBoxRef = useRef(null);
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate(); // React Router hook для навигации
    const { t } = useTranslation(); // Хук для переводов

    useEffect(() => {
        setText(`public class Calculator {
    public int add(int a, int b) {
        return a + b;
    }

    public static void main(String[] args) {
        Calculator calc = new Calculator();
        System.out.println("Sum: " + calc.add(5, 3));
    }
}`);
        if (textBoxRef.current) {
            textBoxRef.current.focus(); // Autofocus on load
        }
    }, []);

    const handleKeyDown = (e) => {
        if (inputIndex < text.length) {
            const currentChar = text[inputIndex];

            if (e.key === currentChar) {
                setInputIndex((prev) => prev + 1);
            } else if (e.key === 'Enter' && currentChar === '\n') {
                let nextIndex = inputIndex + 1;
                while (text[nextIndex] === ' ') {
                    nextIndex++;
                }
                setInputIndex(nextIndex);
            } else if (e.key.length === 1) {
                setErrors((prev) => prev + 1);
            }

            const totalAttempts = inputIndex + 1 + errors;
            setAccuracy(((inputIndex + 1) / totalAttempts) * 100);
        }
        e.preventDefault();
    };

    const resetPractice = () => {
        setInputIndex(0);
        setErrors(0);
        setAccuracy(100);
        if (textBoxRef.current) {
            textBoxRef.current.focus();
        }
    };

    const calculateProgress = () => {
        return ((inputIndex / text.length) * 100).toFixed(2);
    };

    const renderText = () => {
        return text.split('').map((char, index) => {
            let className = '';
            if (index < inputIndex) {
                className = 'correct'; // Правильно введенные символы
            } else if (index === inputIndex) {
                className = 'current'; // Текущий символ с мигающим подчеркиванием
            } else {
                className = 'pending'; // Ожидаемые символы
            }
            return (
                <span key={index} className={className}>
                    {char}
                </span>
            );
        });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Mykola Shchypailo | Typing Practice</title>
                <meta
                    name="description"
                    content="Learn programming with Mykola Shchypailo: courses, tips, and resources for qa engineers and developers."
                />
        </Helmet>

    <section id="portfolio" className="section active">
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__fadeInLeft">
        {/* Кнопка возврата на главную */}
                    <button
                        className="back-to-home"
                        onClick={() => navigate('/')} // Навигация на главную страницу
                    >
                        ⬅ {t('typing.buttons.home')}
                    </button>

                    <div className="container typing-desktop">
                        <div className="section-title text-center animate__animated animate__fadeInDown">
                            <p
                                className="common-desctiption"
                                style={{textTransform: 'lowercase'}}
                                dangerouslySetInnerHTML={{__html: t('typing.description')}}
                            ></p>


                            <h1 className="common-title">
                                {t('typing.title.main')} <span>{t('typing.title.highlight')}</span> {t('typing.title.main2')}
                            </h1>

                            <div className="animated-bar"></div>
                        </div>

                        <div className="typing-practice-container animate__animated animate__fadeInTopLeft">
                            <div
                                className="text-box"
                                tabIndex={0}
                                onKeyDown={handleKeyDown}
                                role="textbox"
                                aria-label={t('typing.meta.description')}
                                ref={textBoxRef}
                            >
                                {renderText()}
                            </div>

                            <div className="stats">
                                <span className="errors">
                                    <span className="icon">❌</span> {t('typing.stats.mistakes')}: {errors}
                                </span>
                                <span className="progress">
                                    <span className="icon">📈</span> {t('typing.stats.progress')}: {calculateProgress()}%
                                </span>
                                <span className="accuracy">
                                    <span className="icon">✅</span> {t('typing.stats.accuracy')}: {accuracy.toFixed(2)}%
                                </span>
                            </div>

                            <div className="button-container">
                                <button className="reset-button" onClick={resetPractice}>
                                    {t('typing.buttons.reset')}
                                </button>
                            </div>
                        </div>
                        <div
                            className="row justify-content-center animate__animated animate__fadeInUp">
                            <div className="col-lg-6 text-center">
                                <h5 className="footer">
                                    &copy; &#160;{currentYear}&#160;&#160;Created by <span
                                    className="highlight-skill">Mykola Shchypailo</span>
                                    &#160;&#160;
                                    <i
                                        className="fas fa-heart animate__animated animate__pulse animate__faster animate__infinite infinite"
                                    ></i>
                                    &#160;&#160;All rights reserved.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default TypingPractice;