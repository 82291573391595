import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import courses from './i18n/courses.json';
import projects from './i18n/projects.json';
import myInfo from './i18n/myInfo.json';
import myResume from './i18n/myResume.json';
import myExpertise from './i18n/myExpertise.json';
import myTestimonials from './i18n/myTestimonials.json';
import myFacts from './i18n/myFacts.json';
import myContact from './i18n/myContact.json';
import springBoot from './i18n/springBoot.json';
import mobileApp from './i18n/mobileApp.json';
import apiRest from './i18n/apiRest.json';
import translation from './i18n/translation.json';
import typing from './i18n/typing.json';

const resources = {
    en: {
        translation: {
            menu: {
                home: "Home",
                blog: "My Courses",
                portfolio: "My Projects",
                about: "About Me",
                contact: "Contact",
            },
            meta: {
                title: "Mykola Shchypailo | Programming courses",
                description: "Learn programming with Mykola Shchypailo: courses, tips, and resources for developers.",
            },
            home: {
                greeting: "Get to know me",
                name: "Mykola Shchypailo",
                role: {
                    software: "AQA Engineer",
                    web: "Web Developer",
                    backend: "Backend Developer",
                },
                description: `
          I am an experienced web and backend developer specializing in 
          <span class="highlight-skill">Java</span>, 
          <span class="highlight-skill">Spring Boot</span>, 
          <span class="highlight-skill">Security</span>, 
          <span class="highlight-skill">Rest API</span>,
          <span class="highlight-skill">MySQL</span>,
          <span class="highlight-skill">React</span>, and 
          <span class="highlight-skill">JavaScript</span>. 
          With expertise in modern frameworks and tools like 
          <span class="highlight-skill">Android Studio</span>,
          <span class="highlight-skill">Xcode</span>,
          <span class="highlight-skill">Appium</span>,
          <span class="highlight-skill">Node.js</span>, 
          <span class="highlight-skill">Docker</span>, 
          <span class="highlight-skill">Kafka</span>, and 
          <span class="highlight-skill">PostgreSQL</span>. 
          <br />My focus is on delivering high-quality results and ensuring each project achieves its goals effectively. 
          I build efficient and scalable solutions tailored to client needs.
        `,
                buttons: {
                    hireMe: "My courses",
                    aboutMe: "About me",
                    typingPractice: "Typing simulator",
                },
            },
            switcher: {
                choose_language: "Choose Language",
                theme: "Choose Theme",
                colors: "Choose Colors",
            },
            // Добавляем переводы из courses.json
            courses: courses.en.courses,
            projects: projects.en.projects,
            myInfo: myInfo.en.myInfo,
            myResume: myResume.en.myResume,
            myExpertise: myExpertise.en.myExpertise,
            myTestimonials: myTestimonials.en.myTestimonials,
            myFacts: myFacts.en.myFacts,
            myContact: myContact.en.myContact,
            springBoot: springBoot.en.springBoot,
            mobileApp: mobileApp.en.mobileApp,
            apiRest: apiRest.en.apiRest,
            translation: translation.en.translation,
            typing: typing.en.typing,
        },
    },
    ru: {
        translation: {
            menu: {
                home: "Главная",
                blog: "Мои курсы",
                portfolio: "Мои проекты",
                about: "Обо мне",
                contact: "Контакты",
            },
            meta: {
                title: "Николай Щипайло | Курсы программирования",
                description: "Изучайте программирование с Николаем Щипайло: курсы, советы и ресурсы для разработчиков.",
            },
            home: {
                greeting: "Знакомся со мной",
                name: "Николай Щипайло",
                role: {
                    backend: "Backend разработчик",
                    web: "Web разработчик",
                    software: "Инженер AQA",
                },
                description: `
          Я опытный web и backend-разработчик, специализирующийся на 
          <span class="highlight-skill">Java</span>, 
          <span class="highlight-skill">Spring Boot</span>, 
          <span class="highlight-skill">Security</span>, 
          <span class="highlight-skill">Rest API</span>,
          <span class="highlight-skill">MySQL</span>,
          <span class="highlight-skill">React</span>, and 
          <span class="highlight-skill">JavaScript</span>. 
          С опытом работы с современными фреймворками и инструментами, такими как 
          <span class="highlight-skill">Android Studio</span>,
          <span class="highlight-skill">Xcode</span>,
          <span class="highlight-skill">Appium</span>,
          <span class="highlight-skill">Node.js</span>,
          <span class="highlight-skill">Docker</span>, 
          <span class="highlight-skill">Kafka</span>, and 
          <span class="highlight-skill">PostgreSQL</span>. 
          <br />Я сосредоточен на достижении высоких результатов и обеспечении успеха каждого проекта. 
          Создаю эффективные и масштабируемые решения, адаптированные под потребности клиента.
        `,
                buttons: {
                    hireMe: "Мои курсы",
                    aboutMe: "Обо мне",
                    typingPractice: "Тренажёр слепой печати",
                },
            },
            switcher: {
                choose_language: "Выберите язык",
                theme: "Выберите тему",
                colors: "Выберите цвет",
            },
            courses: courses.ru.courses,
            projects: projects.ru.projects,
            myInfo: myInfo.ru.myInfo,
            myResume: myResume.ru.myResume,
            myExpertise: myExpertise.ru.myExpertise,
            myTestimonials: myTestimonials.ru.myTestimonials,
            myFacts: myFacts.ru.myFacts,
            myContact: myContact.ru.myContact,
            springBoot: springBoot.ru.springBoot,
            mobileApp: mobileApp.ru.mobileApp,
            apiRest: apiRest.ru.apiRest,
            translation: translation.ru.translation,
            typing: typing.ru.typing,
        },
    },
    ua: {
        translation: {
            menu: {
                home: "Головна",
                blog: "Мої курси",
                portfolio: "Мої проекти",
                about: "Про мене",
                contact: "Контакти",
            },
            meta: {
                title: "Микола Щипайло | Курси програмування",
                description: "Вивчайте програмування з Миколою Щипайло: курси, поради та ресурси для розробників.",
            },
            home: {
                greeting: "Дізнайтеся більше про мене",
                name: "Микола Щипайло",
                role: {
                    backend: "Backend розробник",
                    web: "Web розробник",
                    software: "Інженер AQA",
                },
                description: `
          Я досвідчений web і backend-розробник, що спеціалізується на 
          <span class="highlight-skill">Java</span>, 
          <span class="highlight-skill">Spring Boot</span>, 
          <span class="highlight-skill">Security</span>, 
          <span class="highlight-skill">Rest API</span>,
          <span class="highlight-skill">MySQL</span>,
          <span class="highlight-skill">React</span>, and 
          <span class="highlight-skill">JavaScript</span>.  
          Маю досвід роботи з сучасними фреймворками і інструментами, такими як 
          <span class="highlight-skill">Android Studio</span>,
          <span class="highlight-skill">Xcode</span>,
          <span class="highlight-skill">Appium</span>,
          <span class="highlight-skill">Node.js</span>,
          <span class="highlight-skill">Docker</span>, 
          <span class="highlight-skill">Kafka</span>, and 
          <span class="highlight-skill">PostgreSQL</span>. 
          <br />Я зосереджений на досягненні високих результатів і успіху кожного проєкту. 
          Створюю ефективні і масштабовані рішення, адаптовані до потреб клієнтів.
        `,
                buttons: {
                    hireMe: "Мої курси",
                    aboutMe: "Про мене",
                    typingPractice: "Тренажер сліпого друку",
                },
            },
            switcher: {
                choose_language: "Обрати мову",
                theme: "Обрати тему",
                colors: "Обрати кольори",
            },
            courses: courses.ua.courses,
            projects: projects.ua.projects,
            myInfo: myInfo.ua.myInfo,
            myResume: myResume.ua.myResume,
            myExpertise: myExpertise.ua.myExpertise,
            myTestimonials: myTestimonials.ua.myTestimonials,
            myFacts: myFacts.ua.myFacts,
            myContact: myContact.ua.myContact,
            springBoot: springBoot.ua.springBoot,
            mobileApp: mobileApp.ua.mobileApp,
            apiRest: apiRest.ua.apiRest,
            translation: translation.ua.translation,
            typing: typing.ua.typing,
        },
    },
};

// Определяем язык по умолчанию
const defaultLanguage = localStorage.getItem("language") || "en";

// Инициализация i18next
i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    detection: {
        order: ["localStorage", "navigator"],
        caches: ["localStorage"],
    },
});

i18n.on("languageChanged", (lang) => {
    localStorage.setItem("language", lang);
});

export default i18n;
