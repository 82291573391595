import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NewProfile from "../../images/profile/new-profile.png";
import { MouseContext } from "../context/mouseContext";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { cursorChangeHandler } = useContext(MouseContext);
  const { t } = useTranslation();
  const [isIPhone, setIsIPhone] = useState(false);

  new Date().getFullYear();
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setIsIPhone(true);
    }
  }, []);

  return (
      <React.Fragment>
        <Helmet>
          {/* SEO Page title tag */}
          <title>Mykola Shchypailo | Programming courses</title>
          <meta
              name="description"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for qa engineers and developers."
          />
        </Helmet>

        {/* Index-page Start */}
        <section id="home" className={`section active ${isIPhone ? "no-animation" : ""}`}>
          <div className="homecolor-box"></div>
          <div className="common_bg animate__animated animate__zoomIn">
            <div className="container m-auto">
              <div className="row align-items-center">
                {/* Profile-Pic */}
                <div className="col-xl-5 col-lg-6 col-md-6 col-12">
                  <div className="home-profile animate__animated animate__fadeInLeft">
                    <img src={NewProfile} alt="home-profile" />
                  </div>
                </div>

                {/* Profile-Information */}
                <div className="col-xl-7 col-lg-6 col-md-6 col-12">
                  <div className="home-content">
                    <p className="common-desctiption animate__animated animate__fadeInDown">
                      {t("home.greeting")}
                    </p>
                    <h1 className="common-title animate__animated animate__fadeInDown">
                      {t("home.name")}
                    </h1>
                    <div className="animated-bar animate__animated animate__fadeInDown"></div>
                    <div className={`animated-text ${isIPhone ? "" : "animate__animated animate__fadeInDown"}`}>
                      <h3>{t("home.role.backend")}</h3>
                      <h3>{t("home.role.web")}</h3>
                      <h3>{t("home.role.software")}</h3>
                    </div>

                    {/* Social media icons */}
                    <div className="fixed-block animate__animated animate__jackInTheBox ">
                      <ul className="list-unstyled social-icons">
                        <li onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                          <a href="https://www.youtube.com/@m-shchypailo" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                          <a href="https://www.udemy.com/user/mykola-shchypailo/" target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-graduation-cap"></i>
                          </a>
                        </li>
                        <li onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                          <Link to="https://www.linkedin.com/" target="_blank"
                                rel="noopener noreferrer">
                            <i className="fab fa-linkedin"></i></Link>
                        </li>
                        <li onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                          <Link to="https://github.com/nick8787" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github-square"></i></Link>
                        </li>
                        <li onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                          <a href="https://www.instagram.com/nick_87.87/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram-square"></i>
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* Description */}
                    <p
                        className="lorem-text animate__animated animate__zoomIn"
                        dangerouslySetInnerHTML={{ __html: t("home.description") }}
                    ></p>

                    <div className="home-btn">
                      <Link
                          to="/about"
                          className="clickbtn about-us animate__animated animate__fadeInUp"
                          onMouseEnter={() => cursorChangeHandler("hovered")}
                          onMouseLeave={() => cursorChangeHandler("")}
                      >
                        {t("home.buttons.aboutMe")}
                      </Link>
                      <Link
                          to="/my-courses"
                          className="clickbtn hire-me animate__animated animate__fadeInUp "
                          onMouseEnter={() => cursorChangeHandler("hovered")}
                          onMouseLeave={() => cursorChangeHandler("")}
                      >
                        {t("home.buttons.hireMe")}
                      </Link>
                      <Link
                          to="/touch-typing"
                          className="clickbtn hire-me typing-practice-btn animate__animated animate__fadeInUp"
                          onMouseEnter={() => cursorChangeHandler("hovered")}
                          onMouseLeave={() => cursorChangeHandler("")}
                      >
                        {t("home.buttons.typingPractice")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
  );
};

export default Home;
