import React, { useEffect } from 'react';
import mixitup from 'mixitup';
import { useTranslation } from 'react-i18next';
import Item1 from '../../images/protfolio/item-1.png';
import Item2 from '../../images/protfolio/item-2.png';
import Item3 from '../../images/protfolio/item-3.png';
import Item4 from '../../images/protfolio/item-4.png';
import Item5 from '../../images/protfolio/item-5.png';
import Item6 from '../../images/protfolio/item-8.png';
import Item7 from '../../images/protfolio/item-7.png';
import Item8 from '../../images/protfolio/item-6.png';
import { Helmet } from 'react-helmet';

const Projects = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const mixer = mixitup('.mix-container');
        return () => mixer.destroy();
    }, []);

    const projects = [
        {
            id: 1,
            img: Item1,
            type: 'mobile testing',
            title: t('projects.filters.mobile'),
            post: t('projects.items.post'),
            link: 'https://gitlab.com/nickolaynick87/udemy-joom-project'
        },
        {
            id: 2,
            img: Item2,
            type: 'api testing',
            title: t('projects.filters.api'),
            post: t('projects.items.post'),
            link: 'https://gitlab.com/nickolaynick87/dummyapi-test-project'
        },
        {
            id: 3,
            img: Item3,
            type: 'backend',
            title: t('projects.filters.backend'),
            post: t('projects.items.post'),
            link: 'https://dev.testing87.online/'
        },
        {
            id: 4,
            img: Item4,
            type: 'testing',
            title: t('projects.filters.testing'),
            post: t('projects.items.post'),
            link: 'https://testing87.online/'
        },
        {
            id: 5,
            img: Item5,
            type: 'webdevelopment',
            title: t('projects.filters.webDev'),
            post: t('projects.items.post'),
            link: 'https://github.com/nick8787/post-wave-demo-project'
        },
        {
            id: 6,
            img: Item6,
            type: 'webdevelopment',
            title: t('projects.filters.webDev'),
            post: t('projects.items.post'),
            link: 'https://github.com/nick8787/Java-Selenide-Project'
        },
        {
            id: 7,
            img: Item7,
            type: 'api backend',
            title: t('projects.filters.backend'),
            post: t('projects.items.post'),
            link: 'https://github.com/nick8787/iam-service-jwt-spring-project'
        },
        {
            id: 8,
            img: Item8,
            type: 'api backend',
            title: t('projects.filters.backend'),
            post: t('projects.items.post'),
            link: 'https://github.com/nick8787/post-wave-documentation'
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Mykola Shchypailo | My projects</title>
                <meta
                    name="description"
                    content="Learn programming with Mykola Shchypailo: courses, tips, and resources for qa engineers and developers."
                />
            </Helmet>

            {/* Фон и контент */}
            <section id="portfolio" className="section active">
                <div className="homecolor-box"></div>
                {/* Добавляем цвет фона */}
                <div className="common_bg animate__animated animate__fadeInLeft">
                    <div className="container">
                        {/* Заголовок */}
                        <div className="section-title text-center animate__animated animate__fadeInDown">
                            <p className="common-desctiption">{t('projects.checkOut')}</p>
                            <h1 className="common-title">
                                {t('projects.my')} <span>{t('projects.github')}</span> {t('projects.and')}{' '}
                                <span>{t('projects.demos')}</span>
                            </h1>
                            <div className="animated-bar"></div>
                        </div>

                        {/* Фильтры */}
                        <div className="portfolio-menu animate__animated animate__fadeInUp text-center">
                            <nav className="controls">
                                <button className="control clickbtn" data-filter="all">{t('projects.filters.all')}</button>
                                <button className="control clickbtn" data-filter=".webdevelopment">{t('projects.filters.webDev')}</button>
                                <button className="control clickbtn" data-filter=".backend">{t('projects.filters.backend')}</button>
                                <button className="control clickbtn" data-filter=".testing">{t('projects.filters.testing')}</button>
                                <button className="control clickbtn" data-filter=".mobile">{t('projects.filters.mobile')}</button>
                                <button className="control clickbtn" data-filter=".api">{t('projects.filters.api')}</button>
                            </nav>
                        </div>

                        {/* Список проектов */}
                        <div className="row portfolio-list animate__zoomIn animate__animated">
                            <ul className="row ps-0 mix-container">
                                {projects.map((project) => (
                                    <li key={project.id} className={`mix ${project.type} col-xl-3 col-lg-4 col-md-6`}>
                                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                                            <img src={project.img} alt={t('projects.imageAlt')} />
                                            <div className="info">
                                                <h3 className="title">{project.title}</h3>
                                                <span className="post">{project.post}</span>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Projects;
