import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MouseContext } from "../context/mouseContext";
import Blog1 from '../../images/blog/spring-boot.png';
import Blog2 from '../../images/blog/mobile-app.png';
import Blog3 from '../../images/blog/api-rest.png';
import Blog4 from '../../images/blog/web-selenide.png';
import { Helmet } from 'react-helmet';

const Courses = () => {
  const { cursorChangeHandler } = useContext(MouseContext);
  const { t } = useTranslation();

  const blogData = [
    {
      id: 1,
      image: Blog1,
      date: '31 Aug, 2024',
      title: t('courses.springBoot.title'),
      description: t('courses.springBoot.description'),
      rating: 4.9,
      link: 'https://www.udemy.com/course/spring-boot-java-project/',
    },
    {
      id: 2,
      image: Blog2,
      date: '28 Jun, 2024',
      title: t('courses.appium.title'),
      description: t('courses.appium.description'),
      rating: 4.7,
      link: 'https://www.udemy.com/course/appium-automation-mobile-app-java-cicd-gitlab/',
    },
    {
      id: 3,
      image: Blog3,
      date: '15 Jul, 2024',
      title: t('courses.apiAutomation.title'),
      description: t('courses.apiAutomation.description'),
      rating: 4.6,
      link: 'https://www.udemy.com/course/api-java-rest-assured-maven-postman-cicd',
    },
    {
      id: 4,
      image: Blog4,
      date: '20 Dec, 2024',
      title: t('courses.webAutomation.title'),
      description: t('courses.webAutomation.description'),
    },
  ];

  return (
      <React.Fragment>
        <Helmet>
          <title>Mykola Shchypailo | My courses</title>
          <meta
              name="description"
              content="Learn programming with Mykola Shchypailo: courses, tips, and resources for qa engineers and developers."
          />
        </Helmet>
        <section id="blog" className="section active">
          <div className="homecolor-box"></div>
          <div className="common_bg animate__animated animate__fadeInLeft">
            <div className="container">
              <div className="blog-section text-center">
                <div className="row ">
                  <div className="col-12">
                    <div className="section-title animate__animated animate__fadeInDown">
                      <p className="common-desctiption">{t('courses.checkOut')}</p>
                      <h1 className="common-title">{t('courses.my')} <span>{t('courses.courses')}</span></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-section animate__animated animate__fadeInUp">
                <div className="row justify-content-center">
                  {blogData.map((blog) => (
                      <div key={blog.id} className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-post">
                          <div className="ih-item square effect">
                            <Link
                                to={
                                  blog.id === 1
                                      ? '/spring-boot'
                                      : blog.id === 2
                                          ? '/mobile-app'
                                          : blog.id === 3
                                              ? '/api-automation'
                                              : blog.id === 4
                                                  ? ''
                                                  : '/'
                                }
                                onMouseEnter={() => cursorChangeHandler("hovered")}
                                onMouseLeave={() => cursorChangeHandler("")}
                            >
                              <div className="img">
                                <img src={blog.image} alt="img"/>
                              </div>
                            </Link>
                          </div>
                          <div className="card-body post-content">
                            <div className="content-date-rating">
                              <span className="content-date">{blog.date}</span>
                              {blog.rating && (
                                  <span className="content-rating">
      <span className="rating-label">{t('courses.udemyRating')}:</span>
      <div className="rating-stars">
        {Array.from({length: 5}).map((_, index) => {
          const starType =
              blog.rating >= 4.9 // Если рейтинг >= 4.9
                  ? "full"
                  : blog.rating >= index + 1
                      ? "full" // Полная звезда
                      : blog.rating > index
                          ? "half" // Половина звезды
                          : "empty"; // Пустая звезда

          return <span key={index} className={`star ${starType}`}></span>;
        })}
      </div>
      <span className="rating-value">{blog.rating} / 5</span>
    </span>
                              )}
                            </div>

                            <div className="content-title">
                              <h5>
                                <Link
                                    to={
                                      blog.id === 1
                                          ? '/spring-boot'
                                          : blog.id === 2
                                              ? '/mobile-app'
                                              : blog.id === 3
                                                  ? '/api-automation'
                                                  : blog.id === 4
                                                      ? ''
                                                      : '/'
                                    }
                                    onMouseEnter={() => cursorChangeHandler("hovered")}
                                    onMouseLeave={() => cursorChangeHandler("")}
                                >
                                  {blog.title}
                                </Link>
                              </h5>
                            </div>
                            <div className="content-description">
                              <p dangerouslySetInnerHTML={{__html: blog.description}}/>
                            </div>
                            {blog.link ? (
                                <div className="text-center mt-4">
                                  <button
                                      onMouseEnter={() => cursorChangeHandler("hovered")}
                                      onMouseLeave={() => cursorChangeHandler("")}
                                      onClick={() => window.open(blog.link, "_blank")}
                                      className="clickbtn download-cv"
                                  >
                                    {t('courses.buy')} <i className="fa fa-external-link"/>
                                  </button>
                                </div>
                            ) : (
                                <div className="text-center mt-4">
                                  <button className="clickbtn download-cv disabled" disabled>
                                    {t('courses.comingSoon')} <i className="fa fa-clock-o"/>
                                  </button>
                                </div>
                            )}
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
  );
};

export default Courses;