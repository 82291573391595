import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const FunFactsBox = ({ endValue, suffix = '' }) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    });

    const [animationState, setAnimationState] = useState({
        start: false,
        reset: false,
    });

    const { number } = useSpring({
        from: { number: 0 },
        to: { number: animationState.start ? endValue : 0 },
        reset: animationState.reset,
        config: { duration: 2000 },
        onRest: () => {
            if (animationState.reset) {
                setAnimationState((prev) => ({ ...prev, reset: false }));
            }
        },
    });

    useEffect(() => {
        if (inView && !animationState.start) {
            setAnimationState({ start: true, reset: false });
        } else if (!inView && animationState.start) {
            setAnimationState({ start: false, reset: true });
        }
    }, [inView, animationState.start]);

    return (
        <h3 className="counter" ref={ref}>
            <animated.span>
                {number.to((val) => `${Math.floor(val)}${suffix}`)}
            </animated.span>
        </h3>
    );
};

export default FunFactsBox;
