import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import OwlCarousel from 'react-owl-carousel';
import FunFactsBox from './FunFactsBox';
import { MouseContext } from "../context/mouseContext";
import Simple from '../../images/profile/new-profile.png'
import { Helmet } from 'react-helmet';
import Feedback1 from "../../images/profile/feedback-rz.jpg";
import FeedbackEmpty from "../../images/profile/photo-feedback-empty.png";
import Feedback4 from "../../images/profile/photo-feedback23.jpeg";
import Feedback21 from "../../images/profile/photo-feedback26.jpg";
import Feedback22 from "../../images/profile/photo-feedback22.jpg";
import Feedback7 from "../../images/profile/photo-feedback-empty.png";
import { useTranslation } from 'react-i18next';

const About = () => {

  const { cursorChangeHandler } = useContext(MouseContext);
  const { t } = useTranslation();

  const funFactsData = [
    { value: 5, text: "years of <span>experience</span>" },
    { value: 1200, text: "happy students" },
    { value: 3, text: "completed courses" },
    { value: 15, text: "Countries Reached" }
  ];

  const [options] = useState({
    loop: true,
    margin: 20,
    dots: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  });

  const downloadPdf = () => {
    const pdfFileName = 'CV_Mykola Shchypailo.pdf';

    // Construct the path to the PDF file
    const pdfPath = process.env.PUBLIC_URL + '/pdfs/' + pdfFileName;

    // Fetch the PDF file
    fetch(pdfPath)
      .then((response) => response.blob())
      .then((blob) => {
        // Save the blob as a file using file-saver
        saveAs(blob, pdfFileName);
      })
      .catch((error) => console.error('Error fetching or saving the file:', error));
  };

  const handleButtonClick = () => {
    setShowModal(true);
    setTimeout(() => setShowModal(false), 3000);
  };

  const [showModal, setShowModal] = useState(false);

  // Single Item Data
  const servicesData = [
    {
      id: 1,
      icon: 'fas fa-laptop-code',
      title: 'Web Development',
      description: 'Build dynamic and responsive web applications using React, HTML5, CSS3.',
    },
    {
      id: 2,
      icon: 'fas fa-database',
      title: 'Backend Development',
      description: 'Develop reliable APIs and backend systems with Spring Boot and Java.',
    },
    {
      id: 3,
      icon: 'fas fa-bug',
      title: 'Manual Testing',
      description: 'Validate APIs with Swagger, create test cases, and ensure bug-free solutions.',
    },
    {
      id: 4,
      icon: 'fas fa-tools',
      title: 'Automation Testing',
      description: 'Create automated test solutions with Appium, Selenium, and Rest Assured.',
    },
    {
      id: 5,
      icon: 'fas fa-shield-alt',
      title: 'Security Integration',
      description: 'Secure applications with Spring Security, JWT, and OAuth2 protocols.',
    },
    {
      id: 6,
      icon: 'fas fa-cloud',
      title: 'Cloud & CI/CD',
      description: 'Set up cloud-ready deployments with Docker, GitLab CI/CD, and AWS hosting.',
    },
    {
      id: 7,
      icon: 'fas fa-project-diagram',
      title: 'Microservices',
      description: 'Design microservice architectures with Kafka, Nginx, and distributed systems.',
    },
    {
      id: 8,
      icon: 'fas fa-mobile-alt',
      title: 'Mobile Automation',
      description: 'Automate testing for Android and iOS applications with Appium and Java.',
    },
  ];

  // Testimonials Data
  const testimonialsData = [
    {
      id: 1,
      image: Feedback1,
      name: 'Viktor',
      text: 'Интересно, полезно, кратко и практично. Хотелось бы больше отсылок на теорию и возможно больше вводных формата "что это, для чего, почему"',
      rating: 5,
    },
    {
      id: 2,
      image: FeedbackEmpty,
      name: 'Rinat Sabitov',
      text: 'Good, good, good, but there aren\'t settings for mac and Linux. I use Linux - all answers could be found in internet, but takes a time. Except this - excellent!',
      rating: 4.5,
    },
    {
      id: 2,
      image: Feedback21,
      name: 'Yuriy F.',
      text: 'Без затягувань, чітко по суті, послідовно і зрозуміло, як починати і що за чим запускати.',
      rating: 5,
    },
    {
      id: 3,
      image: FeedbackEmpty,
      name: 'Yevhenii T.',
      text: 'Годний курс - все по ділу і динамічно) <br/> було б круто ще покрити iOS частину.',
      rating: 5,
    },
    {
      id: 4,
      image: FeedbackEmpty,
      name: 'Amelia',
      text: 'Спасибо, жду продолжения! Видео довольно таки очень информативные',
      rating: 5,
    },
    {
      id: 5,
      image: FeedbackEmpty,
      name: 'Саша',
      text: 'Спасибо за подробные и информативные видео',
      rating: 5,
    },
    {
      id: 6,
      image: FeedbackEmpty,
      name: 'Ірина',
      text: 'Добре і чітко все описано. Вже маю досвід але узнале і нове для себе)',
      rating: 4,
    },
    {
      id: 7,
      image: FeedbackEmpty,
      name: 'Ann',
      text: 'Спасибо, хороший материал, интересные видео',
      rating: 5,
    },
    {
      id: 8,
      image: FeedbackEmpty,
      name: 'Denys N.',
      text: 'Якісний матеріал та цікавий проект, приємно слухати лекції, все чітко и швидко',
      rating: 5,
    },
    {
      id: 9,
      image: FeedbackEmpty,
      name: 'Serg',
      text: 'Good course',
      rating: 4,
    },
    {
      id: 10,
      image: FeedbackEmpty,
      name: 'Bbhavani',
      text: 'Thanks for helping me in understanding Spring. Please make more videos and share the subject. Thanks a lot dear.',
      rating: 5,
    },
    {
      id: 11,
      image: FeedbackEmpty,
      name: 'Dmytro',
      text: 'Курс классний, все докладно пояснено, матеріали доступні для розуміння.',
      rating: 4,
    },
    {
      id: 12,
      image: Feedback7,
      name: 'Alex',
      text: 'Good, thx:))',
      rating: 4,
    },
    {
      id: 13,
      image: FeedbackEmpty,
      name: 'Moskalenko N.',
      text: 'Материал подается последовательно и очень подробно.',
      rating: 5,
    },
    {
      id: 14,
      image: Feedback22,
      name: 'Sergiy K.',
      text: 'Great course for beginners!',
      rating: 5,
    },
    {
      id: 15,
      image: FeedbackEmpty,
      name: 'Vital',
      text: 'Курс хороший и нужно еще потратить много времени, чтобы всю эту информацию осмыслить и много раз пересмотреть.',
      rating: 5,
    },
    {
      id: 16,
      image: Feedback4,
      name: 'Mavluda',
      text: 'Поначалу урок мне показался очень скучным и бессмысленным. Но я ошибался. Все круто. Информации из этого видеоурока мне очень помогает. RESPECT',
      rating: 5,
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        {/* SEO Page title tag */}
        <title>Mykola Shchypailo | About me</title>
        {/* Meta description */}
        <meta name="description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for qa engineers and developers." />;

        {/* Twitter card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://avstechnolabs.com/og-images/seo-banner.png" />
        <meta name="twitter:title" content="Mykola Shchypailo | Programming courses" />
        <meta name="twitter:creator" content="@mavi" />
        <meta name="twitter:site" content="@mavi" />
        <meta name="twitter:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers." />

        {/* Facebook card meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nick8787.github.io/m-shchypailo" />
        <meta property="og:title" content="Mykola Shchypailo | Programming courses"
        />
        <meta property="og:description" content="Learn programming with Mykola Shchypailo: courses, tips, and resources for developers."
        />
        <meta property="og:image" content="https://avstechnolabs.com/og-images/seo-banner.png"
        />
      </Helmet>
      {/* About Section Start  */}
      <section id="about" className="section active">
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__fadeInDown">
          <div className="container">
            <div className="about-content">
              {/* About Title Start */}
              <div className="row ">
                <div className="col-12 ">
                  <div className="section-title animate__animated animate__fadeInDown">
                    <p className="common-desctiption">{t('myInfo.intro.description')}</p>
                    <h1 className="common-title" dangerouslySetInnerHTML={{__html: t('myInfo.intro.title')}}></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
              {/* About Title End  */}
              {/* About-Me  */}
              <div className="row single-section">
                <div className="col-lg-4 profile-photo animate__animated animate__fadeInLeft">
                  <div className="row">
                    <div className="col-12 d-block col-sm-none">
                      <img src={Simple} className="img-fluid main-img-mobile" alt={t('myInfo.aboutMe.altImage')}/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                  <div className="row personal-info animate__animated animate__fadeInRight aboutMe-desktop">
                    <div className="col-12">
                      <h5 className="personal-title"
                          dangerouslySetInnerHTML={{__html: t('myInfo.aboutMe.whoAmI')}}></h5>
                      <h3 className="personal-title" dangerouslySetInnerHTML={{__html: t('myInfo.aboutMe.title')}}></h3>
                      <p className="info" dangerouslySetInnerHTML={{__html: t('myInfo.aboutMe.description')}}></p>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        <h3 className="personal-infotitle"
                            dangerouslySetInnerHTML={{__html: t('myInfo.personalInfo.title')}}></h3>
                      </div>

                      <div className="col-lg-6">
                        <ul className="about-you ">
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.firstName')}</span>
                            <span className="value">{t('myInfo.personalInfo.values.firstName')}</span>
                          </li>
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.lastName')}</span>
                            <span className="value">{t('myInfo.personalInfo.values.lastName')}</span>
                          </li>
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.address')}</span>
                            <span className="value">{t('myInfo.personalInfo.values.address')}</span>
                          </li>
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.age')}</span>
                            <span className="value">{t('myInfo.personalInfo.values.age')}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="about-you ">
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.email')}</span>
                            <span className="value">
                <Link
                    href={`mailto:${t('myInfo.personalInfo.values.email')}`}
                    onMouseEnter={() => cursorChangeHandler("hovered")}
                    onMouseLeave={() => cursorChangeHandler("")}
                    style={{textTransform: "lowercase"}}
                >
                  {t('myInfo.personalInfo.values.email')}
                </Link>
              </span>
                          </li>
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.youtube')}</span>
                            <span className="value">
                <a
                    href="https://www.youtube.com/@m-shchypailo"
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => cursorChangeHandler("hovered")}
                    onMouseLeave={() => cursorChangeHandler("")}
                >
                  {t('myInfo.personalInfo.values.youtube')}
                </a>
              </span>
                          </li>
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.udemy')}</span>
                            <span className="value">
                <a
                    href="https://www.udemy.com/user/mykola-shchypailo/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => cursorChangeHandler("hovered")}
                    onMouseLeave={() => cursorChangeHandler("")}
                >
                  {t('myInfo.personalInfo.values.udemy')}
                </a>
              </span>
                          </li>
                          <li>
                            <span className="title">{t('myInfo.personalInfo.info.languages')}</span>
                            <span className="value">{t('myInfo.personalInfo.values.languages')}</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-12">
                        <div className="About-btn">
                          <button
                              onMouseEnter={() => cursorChangeHandler("hovered")}
                              onMouseLeave={() => cursorChangeHandler("")}
                              onClick={handleButtonClick}
                              className="clickbtn download-cv"

                              dangerouslySetInnerHTML={{__html: t('myInfo.personalInfo.downloadCV')}}
                          ></button>
                          {showModal && (
                              <div
                                  className="modal-inline"
                                  style={{
                                    position: 'absolute',
                                    top: '450px', // Adjust vertical position here
                                    left: '10%', // Adjust horizontal position here
                                    transform: 'translateX(-50%)',
                                    background: 'rgba(0, 0, 0, 0.9)',
                                    color: 'white',
                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                    padding: '10px 15px',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                                    fontSize: '14px',
                                    zIndex: 1000,
                                    whiteSpace: 'nowrap',
                                  }}
                              >
                                <p>{t('myContact.form.modal.notReady')}</p>
                              </div>
                          )}
                          <div className="col-lg-7 col-sm-6 col-12">
                            <ul className="list-unstyled social-icons">
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <a href="https://www.youtube.com/@m-shchypailo" target="_blank"
                                   rel="noopener noreferrer">
                                  <i className="fab fa-youtube"></i>
                                </a>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <a href="https://www.udemy.com/user/mykola-shchypailo/" target="_blank"
                                   rel="noopener noreferrer">
                                  <i className="fas fa-graduation-cap"></i>
                                </a>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <Link to="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                  <i className="fab fa-linkedin"></i>
                                </Link>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <Link to="https://github.com/nick8787" target="_blank" rel="noopener noreferrer">
                                  <i className="fab fa-github-square"></i>
                                </Link>
                              </li>
                              <li onMouseEnter={() => cursorChangeHandler("hovered")}
                                  onMouseLeave={() => cursorChangeHandler("")}>
                                <a href="https://www.instagram.com/nick_87.87/" target="_blank"
                                   rel="noopener noreferrer">
                                  <i className="fab fa-instagram-square"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* About-Me End  */}


              {/* Resume Section Start */}
              <div className="resume-section">
                {/* Resume Title */}
                <div className="row">
                  <div className="col-12">
                    <div className="section-title animate__animated animate__fadeInUp">
                      <p className="common-desctiption">{t('myResume.intro.description')}</p>
                      <h1 className="common-title" dangerouslySetInnerHTML={{__html: t('myResume.intro.title')}}></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
                {/* Resume Content */}
                <div className="row">
                  {/* Education Part */}
                  <div className="col-md-6 col-12">
                    <div className="col-block education education-desktop">
                      <h3 className="about-subtitle">{t('myResume.education.title')}</h3>
                      {t('myResume.education.items', {returnObjects: true}).map((item, index) => (
                          <div className="resume-item" key={index}>
                            <span className="item-arrow"></span>
                            <h5 className="item-title">{item.title}</h5>
                            <span className="item-details">{item.details}</span>
                            <p className="item-description">{item.description}</p>
                          </div>
                      ))}
                    </div>
                  </div>
                  {/* Experience Part */}
                  <div className="col-md-6 col-12">
                    <div className="col-block education education-desktop">
                      <h3 className="about-subtitle">{t('myResume.experience.title')}</h3>
                      {t('myResume.experience.items', {returnObjects: true}).map((item, index) => (
                          <div className="resume-item" key={index}>
                            <span className="item-arrow"></span>
                            <h5 className="item-title">{item.title}</h5>
                            <span className="item-details">{item.details}</span>
                            <p className="item-description">{item.description}</p>
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* Resume Section End */}
              {/* / Resume section End */}
              {/* Skill Bar Section  */}
              {/*<div className="skill-section">*/}
              {/*  <div className="row">*/}
              {/*    <div className="col-12 ">*/}
              {/*      <div className="section-title animate__animated animate__fadeInUp animate__delay-3s">*/}
              {/*        <p className="common-desctiption">My level of knowledge in some tools</p>*/}
              {/*        <h1 className="common-title">my <span>skills</span></h1>*/}
              {/*        <div className="animated-bar"></div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-lg-6 col-md-12 ">*/}
              {/*      <h3 className="about-subtitle">Design <span>Skills</span></h3>*/}
              {/*      <div className="skill-bars">*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Photoshop</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Photoshop">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Illustrator</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Illustrator">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Figma</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Figma">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Indesign</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Indesign">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Sketch</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line Sketch">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-lg-6 col-md-12 ">*/}
              {/*      <h3 className="about-subtitle">Coding <span>Skills</span></h3>*/}
              {/*      <div className="skill-bars">*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>HTML</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line html">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>CSS</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line css">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>jQuery</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line jquery">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>Python</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line python">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*        <div className="bar">*/}
              {/*          <div className="info">*/}
              {/*            <span>MySQL</span>*/}
              {/*          </div>*/}
              {/*          <div className="progress-line mysql">*/}
              {/*            <span></span>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/* / Skill Bar Section  */}

              /* My Expertise Section */
              <div className="services-section text-center">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title animate__animated animate__fadeInUp animate__slower">
                      <p className="common-desctiption">{t('myExpertise.intro.description')}</p>
                      <h1 className="common-title"
                          dangerouslySetInnerHTML={{__html: t('myExpertise.intro.title')}}></h1>
                      <div className="animated-bar"></div>
                    </div>
                    <p className="service-text" dangerouslySetInnerHTML={{__html: t('myExpertise.intro.text')}}></p>
                  </div>
                </div>
                <div className="row animate__animated animate__zoomIn animate__slower">
                  {servicesData.map((service, index) => (
                      <div key={index}
                           className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower">
                        <div className="service-item">
                          <i className={service.icon}></i>
                          <h4>
                            <span className="service-line">{service.title}</span>
                          </h4>
                          <p>{t(`myExpertise.services.${index}.description`)}</p>
                        </div>
                      </div>
                  ))}
                </div>
              </div>

              {/* My Testimonials Section */}
              <div className="myTestimonials-section">
                <div className="row">
                  <div className="col-12 animate__animated animate__fadeInDown">
                    <div className="section-title">
                      <p className="common-desctiption">{t('myTestimonials.intro.description')}</p>
                      <h1 className="common-title"
                          dangerouslySetInnerHTML={{__html: t('myTestimonials.intro.title')}}></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <OwlCarousel className="owl-theme" id="testimonial" {...options}>
                    {testimonialsData.map((testimonial, index) => (
                        <div key={index} className="item">
                          <div className="testimonial-item">
                            <div className="quote">
                              <i className="fas fa-quote-left"></i>
                            </div>
                            <div className="testimonial-img">
                              <img src={testimonial.image} alt={`testimonial-${index}`}/>
                            </div>
                            <h5 className="highlight-skill">{testimonial.name}</h5>
                            <p dangerouslySetInnerHTML={{__html: testimonial.text}}/>
                            <div className="rating">
                              {Array.from({length: testimonial.rating}, (_, idx) => (
                                  <i key={idx} className="fas fa-3x fa-star"></i>
                              ))}
                            </div>
                          </div>
                        </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
              {/* End of Testimonials Section */}

              {/* My Fun Facts Section */}
              <div className="funfacts-section">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title">
                      <p className="common-desctiption">{t('myFacts.intro.description')}</p>
                      <h1 className="common-title" dangerouslySetInnerHTML={{__html: t('myFacts.intro.title')}}></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={5}/>
                      <p className="fun-text" dangerouslySetInnerHTML={{__html: t('myFacts.facts.0.text')}}></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={1700}/>
                      <p className="fun-text" dangerouslySetInnerHTML={{__html: t('myFacts.facts.1.text')}}></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={3}/>
                      <p className="fun-text" dangerouslySetInnerHTML={{__html: t('myFacts.facts.2.text')}}></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <FunFactsBox endValue={15}/>
                      <p className="fun-text" dangerouslySetInnerHTML={{__html: t('myFacts.facts.3.text')}}></p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of My Fun Facts Section */}

              <style jsx>{`
                .modal-inline {
                  position: absolute;
                  top: 450px; /* Для десктопа */
                  left: 10%; /* Для десктопа */
                  transform: translateX(-50%);
                  background: rgba(0, 0, 0, 0.8);
                  color: white;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                  padding: 10px 15px;
                  border-radius: 8px;
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                  font-size: 14px;
                  z-index: 100;
                  white-space: nowrap;
                }

                .modal-inline p {
                  margin: 0;
                }

                @media (max-width: 768px) {
                  .modal-inline {
                    top: 84% !important;
                    left: 32% !important; 
                    transform: translate(-50%, -50%) !important;
                    width: 60% !important;
                    max-width: 320px !important; 
                    padding: 10px !important; 
                    font-size: 14px !important;
                    white-space: normal !important; 
                    word-wrap: break-word !important; 
                    text-align: center !important;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3) !important; 
                    border-radius: 8px !important; 
                  }

                  .modal-inline p {
                    margin: 0 !important;
                    line-height: 1.5 !important; 
                  }
                }


              `}</style>

            </div>
          </div>
        </div>
      </section>
      {/* About Section End  */}
    </React.Fragment>
  )
}

export default About